export const defaultServices = [
  {
    id: 'ce2510f5-bd4c-473b-b77e-086339a0c76f',
    displayKey: 'webdesign',
    sequence: 1,
    services: [
      {
        id: 'a276b167-e038-4545-9c33-b859e3568a9e',
        displayKey: 'classicwebsite',
        sequence: 1,
        hidden: false,
      },
      {
        id: '09569b86-c8fc-4d02-99ce-a452f434e4d9',
        displayKey: 'advancedwebsite',
        sequence: 2,
        hidden: false,
      },
      {
        id: 'ba0f47b7-efb7-4325-bcb8-fee61e0bc834',
        displayKey: 'redesignsite',
        sequence: 3,
        hidden: false,
      },
      {
        id: 'ebd0ba79-eb2d-499e-b6cd-3e93a78d518f',
        displayKey: 'movesite',
        sequence: 4,
        hidden: false,
      },
      {
        id: 'd6865737-811e-4765-9a04-7b0b458e1130',
        displayKey: 'mobilesite',
        sequence: 5,
        hidden: false,
      },
      {
        id: 'e86b0978-352a-44d4-a89a-50e5a66d3b6f',
        displayKey: 'editor-x-build-from-scratch',
        sequence: 13,
        hidden: false,
      },
    ],
    revision: { low: 1, high: 0, unsigned: true },
    createdDate: '2022-06-07T12:39:55.543Z',
  },
  {
    id: '58ecd742-47f7-4519-95df-00e38a85a98c',
    displayKey: 'smalltasks',
    sequence: 2,
    services: [
      {
        id: '07a0582a-5eff-48e9-a1ef-d0a0e73c68ec',
        displayKey: 'domain',
        sequence: 2,
        hidden: false,
      },
      {
        id: 'be4031f9-16b5-49ad-8f91-32a2ddd8485f',
        displayKey: 'updates',
        sequence: 3,
        hidden: false,
      },
      {
        id: '22b2cc0f-39b3-4800-abfc-d451b689d56f',
        displayKey: 'siteguidance',
        sequence: 4,
        hidden: false,
      },
      {
        id: 'd5108a1f-5ea1-4eca-b63e-963a9362336b',
        displayKey: 'installapps',
        sequence: 4,
        hidden: false,
      },
      {
        id: '5b8d16f0-7858-41a4-a9df-0a8a11ecd662',
        displayKey: 'email',
        sequence: 5,
        hidden: false,
      },
      {
        id: 'ab93767c-3e26-4b19-819b-abe0a7b1353e',
        displayKey: 'pixel',
        sequence: 6,
        hidden: false,
      },
      {
        id: '9de611fe-524b-4dca-b5c1-7d73f8baa51e',
        displayKey: 'analytics',
        sequence: 7,
        hidden: false,
      },
      {
        id: '3e57a1f2-49e0-4394-b656-65206fca7161',
        displayKey: 'accessibility',
        sequence: 8,
        hidden: false,
      },
      {
        id: '05ba1c0d-b1c7-45ed-82ad-6dda69aeb2e5',
        displayKey: 'restaurant-menu-setup',
        sequence: 9,
        hidden: false,
      },
    ],
    revision: { low: 1, high: 0, unsigned: true },
    createdDate: '2022-06-07T12:19:02.558Z',
  },
  {
    id: 'be7e6e7d-0ea0-4250-9ad7-7fd53bf61bd8',
    displayKey: 'onlinestore',
    sequence: 3,
    services: [
      {
        id: '2f6dcd2b-9dbd-41e7-a5f9-338c3601ff0e',
        displayKey: 'ecommercesite',
        sequence: 1,
        hidden: false,
      },
      {
        id: 'e3607ea3-fe0a-4dc9-a90a-4e9d56c288b9',
        displayKey: 'advancedstore',
        sequence: 1,
        hidden: false,
      },
      {
        id: '99b8ae14-292b-49ab-9f63-8e935864ab58',
        displayKey: 'storesettings',
        sequence: 2,
        hidden: false,
      },
      {
        id: '0913d834-654c-4400-b6ca-78679aff41a2',
        displayKey: 'storeopt',
        sequence: 3,
        hidden: false,
      },
      {
        id: 'a602b21a-04f0-4b9e-9269-8240fd39ab16',
        displayKey: 'advancedstorecapabilities',
        sequence: 4,
        hidden: false,
      },
    ],
    revision: { low: 1, high: 0, unsigned: true },
    createdDate: '2022-06-07T12:20:04.440Z',
  },
  {
    id: '1707e085-5f2a-434c-9be9-390760c4e2ba',
    displayKey: 'webdev',
    sequence: 4,
    services: [
      {
        id: '6ddd5415-76de-423f-b518-8ec62c1e2edf',
        displayKey: 'codingguidance',
        sequence: 1,
        hidden: false,
      },
      {
        id: '5fd8a301-2a36-40b4-88b8-bbf3efe5cb41',
        displayKey: 'customsitefunctionalities',
        sequence: 2,
        hidden: false,
      },
      {
        id: '6adcdfa0-0743-48df-a6ef-ee3a7df7a700',
        displayKey: 'thirdpartyservices',
        sequence: 4,
        hidden: false,
      },
      {
        id: 'bc892681-b108-4ea8-b04c-c40b6bd52694',
        displayKey: 'contentwithdatabase',
        sequence: 5,
        hidden: false,
      },
      {
        id: '1f1e375c-6583-4054-84b0-4fa7160197b7',
        displayKey: 'custominputforms',
        sequence: 6,
        hidden: false,
      },
      {
        id: 'df4d93c1-a4b9-44e0-9482-c8732db7eec7',
        displayKey: 'customdesign',
        sequence: 7,
        hidden: false,
      },
    ],
    revision: { low: 1, high: 0, unsigned: true },
    createdDate: '2022-06-07T12:18:19.877Z',
  },
  {
    id: 'b53bfd75-31a6-4008-a9e9-09d32233f0d4',
    displayKey: 'marketingandpromoting',
    sequence: 5,
    services: [
      {
        id: 'e5a2bfbf-0be2-45da-855d-519ec6e4015c',
        displayKey: 'seo',
        sequence: 1,
        hidden: false,
      },
      {
        id: '3d583c34-8d50-4b17-9859-0649bb2b557f',
        displayKey: 'ads',
        sequence: 2,
        hidden: false,
      },
      {
        id: '89de24f9-ccf7-4c0c-b769-3ddb0f042a7b',
        displayKey: 'written',
        sequence: 3,
        hidden: false,
      },
      {
        id: '7390abbb-b278-470f-83be-6d85bcccfb5b',
        displayKey: 'emailmarketing',
        sequence: 5,
        hidden: false,
      },
    ],
    revision: { low: 1, high: 0, unsigned: true },
    createdDate: '2022-06-07T12:19:31.966Z',
  },
  {
    id: 'fed24aaf-2432-482f-a56c-40f44273b82c',
    displayKey: 'graphicdesign',
    sequence: 6,
    services: [
      {
        id: 'da660657-e255-4f72-ba03-c7cac757bce3',
        displayKey: 'logo',
        sequence: 1,
        hidden: false,
      },
      {
        id: 'ec97a8c1-2f64-4b6e-827b-4fcbc976c9f9',
        displayKey: 'editimage',
        sequence: 2,
        hidden: false,
      },
      {
        id: '6d370fae-2896-469b-9c8f-11f8a39d508a',
        displayKey: 'visuals',
        sequence: 3,
        hidden: false,
      },
      {
        id: '40f83a36-5853-434d-b3f1-2a41f4de70dd',
        displayKey: 'branddev',
        sequence: 4,
        hidden: false,
      },
      {
        id: '90079751-717b-4c0e-83fa-3500a57c24f8',
        displayKey: 'photos',
        sequence: 5,
        hidden: false,
      },
    ],
    revision: { low: 1, high: 0, unsigned: true },
    createdDate: '2022-06-07T12:20:29.930Z',
  },
];

export const dataHooks = {
  marketplaceHeader: 'marketplace-header',
  currencySelector: 'currency-selector',
  languageSelector: 'language-selector',
  currencySelectorLink: 'currency-selector-link',
  languageSelectorLink: 'language-selector-link',
  loggedinArea: 'loggedin-area',
  loginBtn: 'login-btn',
  logoutBtn: 'logout-btn',
  myProjectsLink: 'my-projects-link',
  contactSupportLink: 'contactSupport-link',
  accountSettingsLink: 'accountSettings-link',
  wixLink: 'wix-link',
  joinLink: 'join-link',
  studioTemplatesLink: 'studio-templates-link',
  sellInMarketplaceDropdown: 'sell-in-marketplace-dropdown',

  categoryLinkAccordion: 'category-link-accordion',
  serviceLinkAccordion: 'service-link-accordion',
  sellInMarketplaceLinkAccordion: 'sell-in-marketplace-link-accordion',
  categoryAccordion: (key?: string) => `category-accordion-${key}`,
  mobileServiceLink: (key?: string) => `mobile-service-link-${key}`,
  mobileCategoryLink: (key?: string) => `mobile-category-link-${key}`,
};

export const popoverIds = {
  currencyPopover: 'mph-currency-selector',
  languagePopover: 'mph-language-selector',
  loggedinPopover: 'mph-login-popover',
  loggedinPopoverContent: 'mph-login-popover-content',
};

export const getServiceLink = (
  marketplaceUrlPrefix: string,
  servicePathName: string,
  categoryPathName?: string,
) => {
  return categoryPathName
    ? `${marketplaceUrlPrefix}/hire/${categoryPathName}/${servicePathName}`
    : `${marketplaceUrlPrefix}/hire/${servicePathName}`;
};

export const EXPERIMENTS_SCOPE = 'marketplace';

export const loginUrl = (redirectToUrl: string) =>
  `https://users.wix.com/signin?loginDialogContext=login&redirectTo=${encodeURIComponent(
    redirectToUrl,
  )}`;
export const logoutUrl = (redirectToUrl: string) =>
  `https://www.wix.com/signout?redirectTo=${encodeURIComponent(redirectToUrl)}`;
export const myProjectsUrl = 'https://manage.wix.com/account/hire-a-partner';
export const myProjectsNewPath = '/my-projects';
export const contactSupportUrl = 'https://www.wix.com/contact';
export const accountSettingsUrl =
  'https://manage.wix.com/account/account-settings';
export const wixUrl = 'https://www.wix.com';
export const LOGO_WIDTH = '154';
export const LOGO_HEIGHT = '23';
