import webBiLogger from '@wix/web-bi-logger';

interface LogObject {
  evid: number;
  endpoint: string;
  src: number;
  params: { [key: string]: any };
}

export const useBi = () => {
  const biLogger = webBiLogger.factory().logger();

  const report = async (event: LogObject): Promise<void> => {
    await biLogger.report(event);
  };

  return { report };
};
