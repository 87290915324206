export const WIX_DOMAIN = 'wix.com';
export const EDITORX_DOMAIN = 'editorx.com';

export const USER_LOGIN_PARAM = 'loggedIn';
export const TEMPLATE_CHECKOUT_SITE_ID = '548f7aff-604e-45a3-92c5-7e3b7f7f1a67';
export const TEMPLATE_CHECKOUT_APP_ID = 'f6e7302a-049c-45a2-a116-ee40aac719b0';
export const ARTIFACT_ID = 'partners-marketplace-nextjs';
export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_CURRENCY_SYMBOL = '$';
export const MAX_PRICE_IN_USD = 20000;
export const MIN_PRICE = 0;
export const MARKETPLACE_URL = '/marketplace/';
export const MAX_ABOUT_LENGTH = 256;
export const MAX_CHARS_IN_REVIEW = 200;
export const BRIEF_PAGE = 'brief-auto-match';
export const BRIEF_URL = `https://www.wix.com/marketplace/${BRIEF_PAGE}`;
export const TEMPLATES_API_URL = '/api/templates';
export const TEMPLATE_REDEMPTION_API_URL = '/api/template/redemption';
export const TEMPLATE_POST_REVIEW_API_URL = '/api/template/review';
export const TEMPLATE_DELETE_REVIEW_API_URL = '/api/template/deleteReview';
export const TEMPLATE_UPDATE_REVIEW_API_URL = '/api/template/updateReview';
export const TEMPLATE_POST_REVIEW_REPLY_API_URL =
  '/api/template/writeReviewReply';
export const TEMPLATE_DELETE_REVIEW_REPLY_API_URL =
  '/api/template/deleteReviewReply';
export const TEMPLATE_CHECKOUT_API_URL = '/api/template/checkout';
export const FILL_JOB_BRIEF_URL = '/_api/marketplace-webapp/fillJobBrief';
export const SUBMIT_DIRECT_BRIEF_URL =
  '/_api/marketplace-webapp/submitJobRequest';
export const SUBMIT_BRIEF_DIRECT_URL = '/api/submitBrief/direct';
export const SUBMIT_BRIEF_ADDITIONAL_PROJECT_URL =
  '/api/submitBrief/additional-project';
export const SUBMIT_AUTO_MATCH_BRIEF_URL = '/api/submitBrief/auto-match';
export const TERMS_OF_USE_URL = 'https://www.wix.com/marketplace/terms-of-use';
export const SEO_SOCIALS_IMAGE =
  'https://static.wixstatic.com/media/375882_750a887236d5435cad3ce1cdc20050d6~mv2.jpg';
export const REPLY_TO_REVIEW_URL = '/_api/marketplace-webapp/addReplyToReview/';
export const EXPLORE_URL = 'https://www.wix.com/marketplace/hire';
export const STUDIO_SITES_URL = 'https://manage.wix.com/studio/sites';
export const TAX_GROUP_ID = 'f6e7302a-049c-45a2-a116-ee40aac719b0';

export const MY_PROJECTS = 'my-projects';
export const PARTNER_PROFILE = 'wix-partner';
export const TEMPLATES_GALLERY_ENTRY = 'gallery_page';

export const enableInternalMarketplaceSpec =
  'com.wixpress.experts.lib.callscope.enrich.EnableInternalMarketplace';

export const SEO_EXPLORE_PAGE_MAP: Record<
  string,
  { title: string; description: string; h1: string }
> = {
  'web-design': {
    title: 'seo-explore-page-title-webdesign',
    description: 'seo-explore-page-description-webdesign',
    h1: 'explore-page-heading-webdesign',
  },
  'small-tasks': {
    title: 'seo-explore-page-title-smalltasks',
    description: 'seo-explore-page-description-smalltasks',
    h1: 'explore-page-heading-smalltasks',
  },
  'online-store': {
    title: 'seo-explore-page-title-onlinestore',
    description: 'seo-explore-page-description-onlinestore',
    h1: 'explore-page-heading-onlinestore',
  },
  'web-developer': {
    title: 'seo-explore-page-title-webdev',
    description: 'seo-explore-page-description-webdev',
    h1: 'explore-page-heading-webdev',
  },
  'marketing-promoting': {
    title: 'seo-explore-page-title-marketingandpromoting',
    description: 'seo-explore-page-description-marketingandpromoting',
    h1: 'explore-page-heading-marketingandpromoting',
  },
  'graphic-designer': {
    title: 'seo-explore-page-title-graphicdesign',
    description: 'seo-explore-page-description-graphicdesign',
    h1: 'explore-page-heading-graphicdesign',
  },
  'seo-services': {
    title: 'seo-explore-page-title-seo',
    description: 'seo-explore-page-description-seo',
    h1: 'explore-page-heading-seo',
  },
  'logo-design-services': {
    title: 'seo-explore-page-title-logo',
    description: 'seo-explore-page-description-logo',
    h1: 'explore-page-heading-logo',
  },
  default: {
    title: 'seo-explore-page-title-default',
    description: 'seo-explore-page-description-default',
    h1: 'explore-page-heading-default',
  },
};

export const ALTERNATE_LANGUAGES = [
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'id',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pl',
  'pt',
  'ru',
  'sv',
  'th',
  'tr',
  'uk',
  'vi',
  'zh',
];

export const TAX_INCLUSIVE_COUNTRIES = ['IL', 'AU', 'DE', 'FR'];

export const PROFILE_NOT_FOUND = 'NOT_FOUND';
export const TEMPLATE_NOT_FOUND = 'NOT_FOUND';

export const INVALID_ARGUMENT = 'INVALID_ARGUMENT';

export const DEFAULT_PRICING = { min: MIN_PRICE, max: NaN };

export const KeyCodeEnter = 13;

export const ClientStatus = {
  REQUEST_SENT: 'REQUEST_SENT',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLTETD',
  CANCELED: 'CANCELED',
};

export const PROJECT_PAGE = 'client-project-page';
export const MY_PROJECTS_PAGE = 'my-projects-page';

export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';

export const CANCELATION_REASON_KEYS = {
  NO_NEED: {
    key: 'client-dashboard-client-projects-cancel-option-no-need',
    biReason: 'I dont need it anymore',
  },
  HIRED_SOMEONE_ELSE: {
    key: 'client-dashboard-client-projects-cancel-option-hired-someone-else',
    biReason: 'I hired someone else',
  },
  FOUND_CHEAPER: {
    key: 'client-dashboard-client-projects-cancel-option-someone-less-expensive',
    biReason: 'I found someone else less expensive',
  },
  SELF_MADE: {
    key: 'client-dashboard-client-projects-cancel-option-self-made',
    biReason: 'I decided to do it myself',
  },
  TOO_EXPENSIVE: {
    key: 'client-dashboard-client-projects-cancel-option-expensive',
    biReason: 'Its too expensive',
  },
};
export const CAPSULE_EXPIRATION_IN_SECONDS = 60 * 60; // 60 minutes
export const DATA_CAPSULE_KEY_FOR_BRIEF_PAGE_SUBMITTED =
  'briefPageSubmittedDetails';

export const MORE_DETAILS_SLUG = 'more-details';
export const SITE_SELECTOR_SLUG = 'site-selector';
export const SEO_SERVICES_SLUG = 'seo-services';

export const MOBILE_VIEW_SIZE = '1000px';
export const MAX_CHARACTER_LIMIT_FOR_SITE_NAME = 20;

export const Sections = {
  EMPTY: '',
  ABOUT: 'about',
  FEATURED: 'featured',
  REVIEWS: 'reviews',
};

export const ENABLE_SHOW_EDITOR_PLATFORM =
  'specs.experts.showEditorPlatformOnProfilePage';

export const ENABLE_DEEP_LINK_TO_MORE_INFO_STEP_SPEC =
  'specs.experts.EnableBriefDeepLinkToMoreInfoStep';

export const PARTNERS_MARKETPLACE_GETUSERDETAILS_PERMISSIONS =
  'PARTNERS.MARKETPLACE.GETUSERDETAILS';

export const ENABLE_MARKETPLACE_TEMPLATES_SPEC =
  'specs.marketplace.enableMarketplaceTemplates';

export const ENABLE_MARKETPLACE_TEMPLATES_SPEC_V2 =
  'specs.marketplace.enableTemplateGalleryForLoggedInUser';

export const ENABLE_MARKETPLACE_TEMPLATES_DEBUG_MODE =
  'specs.marketplace.enableMarketplaceTemplatesDebugMode';

export const ENABLE_TEMPLATES_CURRENCY_CONVERSION_SPEC =
  'specs.marketplace.enableMarketplaceTemplatesCurrencyConversion';

export const ENABLE_TEMPLATES_SORT_BY_BEST_SELLER =
  'specs.marketplace.enableSortByBestSeller';

export const ENABLE_REPORT_TEMPLATE = 'specs.experts.marketplaceReportTemplate';

export const ENABLE_TEMPLATES_MULTI_LANGUAGE_SPEC =
  'specs.marketplace.EnableTemplatesMultiLanguage';

export const ENABLE_DISPLAY_STUDIO_BADGE_ON_PORTFOLIO_ITEMS_SPEC =
  'specs.marketplace.EnableDisplayStudioBadgeOnPortfolioItems';

export const ENABLE_PARTNER_DISPUTES_SPEC =
  'specs.marketplace.EnablePartnerDisputes';

export const ENABLE_BRIEF_AI_SPEC = 'specs.marketplace.EnableBriefAI';

export const ENABLE_VELO_MODAL_NEW_CONTENT =
  'specs.experts.templatesVeloModalNewContent';

export const CUSTOM_CODE_KB_LINK =
  'https://wix.com/support/html5/article/wix-studio-about-custom-code-in-marketplace-templates';
