import { useMediaQuery } from 'react-responsive';
import { MOBILE_VIEW_SIZE } from '@utils/constants';

const useMobileMediaQuery = (fallback?: boolean) => {
  const isMobileByMediaQuery = useMediaQuery({
    query: `(max-width: ${MOBILE_VIEW_SIZE})`,
  });
  if (typeof window !== 'undefined') {
    return isMobileByMediaQuery;
  } else {
    return fallback ?? false;
  }
};

export default useMobileMediaQuery;
